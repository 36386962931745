import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const HanabusaIVFLandingPage = () => (
  <PhysicianLandingPage
    physician="Hanabusa IVF"
    institution="Hanabusa IVF"
    referralCode="HANABUSAIVF"
    physicianURL="https://www.hanabusaivf.com/"
  />
)

export default HanabusaIVFLandingPage
